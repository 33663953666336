define('personal-website/main/about-me/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    // attributes
    session: service('session'),
    sessionAccount: service('session-account'),
    // hooks
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
      controller.set('profilePickOptions', {
        accept: 'image/*',
        fromSources: 'local_file_system',
        maxFiles: 1,
        minFiles: 1
      });
      controller.set('resumePickOptions', {
        accept: '.pdf',
        fromSources: 'local_file_system',
        maxFiles: 1,
        minFiles: 1
      });
    },
    // actions
    actions: {
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/about-me.jpg)');
      },
      save: function save() {
        this.controller.get('model').save();
      },
      uploadProfilePic: function uploadProfilePic(results) {
        var _this = this;

        if (results[0]) {
          (function () {
            var result = results[0];

            _ember['default'].RSVP.Promise.resolve(_this.get('sessionAccount.profile')).then((function (person) {
              this.store.createRecord('image', {
                fileName: result.name,
                url: result.url,
                uploader: person
              }).save().then((function (image) {
                person.set('profilePicture', image);

                person.save().then((function () {
                  this.refresh();
                }).bind(this));
              }).bind(this));
            }).bind(_this));
          })();
        }
      },
      uploadResume: function uploadResume(results) {
        var _this2 = this;

        if (results[0]) {
          (function () {
            var result = results[0];

            _ember['default'].RSVP.Promise.resolve(_this2.get('sessionAccount.profile')).then((function (person) {
              person.set('resumeUrl', result.url);

              person.save().then((function () {
                this.refresh();
              }).bind(this));
            }).bind(_this2));
          })();
        }
      },
      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});