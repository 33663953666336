define('personal-website/components/page-footer/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    date: new Date().getFullYear(),
    tagName: '',
    actions: {
      login: function login() {
        this.sendAction('login');
      }
    }
  });
});