define('personal-website/components/status-messages/component', ['exports', 'ember'], function (exports, _ember) {
  // const log = Ember.Logger.log;

  exports['default'] = _ember['default'].Component.extend({
    // attributes
    alertType: '',
    error: '',
    success: '',
    tagName: '',
    // computed properties
    message: _ember['default'].computed('error', 'success', function () {
      return this.get('error') || this.get('success');
    }),
    wrapText: _ember['default'].computed('allowWrap', 'message.length', function () {
      return this.get('message.length') > 0 && this.get('allowWrap');
    }),
    // observers
    // set timer when error is received
    watchError: (function () {
      if (this.get('error')) {
        this.set('alertType', 'alert-danger');
        _ember['default'].run.later((function () {
          this.set('error', '');
        }).bind(this), this.get('timeout'));
      }
    }).observes('error'),
    // handle showing message
    watchMessage: (function () {
      var element = $(this.element).find('.alert');

      if (this.get('message')) {
        _ember['default'].run.later(function () {
          $(element).addClass('wrap');
        }, 300);
      } else {
        $(element).removeClass('wrap');
      }
    }).observes('message'),
    // set timer when success is received
    watchSuccess: (function () {
      if (this.get('success')) {
        this.set('alertType', 'alert-success');
        _ember['default'].run.later((function () {
          this.set('success', '');
        }).bind(this), this.get('timeout'));
      }
    }).observes('success'),
    // event hooks
    didReceiveAttrs: function didReceiveAttrs() {
      var t = this.get('timeout'),
          transition = this.get('transition');

      this.set('timeout', t ? t : 3000);

      this.set('transition', transition ? transition : 'hide-vertical');
    }
  });
});