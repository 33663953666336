define('personal-website/login/route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    session: service('session'),
    genericError: '<p>Could not authenticate your account with the provided information.</p><p>Please try again</p>',
    actions: {
      authenticate: function authenticate() {
        this.actions.authenticateWithOAuth2.call(this);
        $('button').blur();
      },
      authenticateWithOAuth2: function authenticateWithOAuth2() {
        var _controller$getProperties = this.controller.getProperties('username', 'password');

        var username = _controller$getProperties.username;
        var password = _controller$getProperties.password;
        var ctrl = this.controller;
        var session = this.get('session');

        // Ensure error message is blank to start
        ctrl.set('error', null);

        // set login value on session
        session.set('data.login', username);

        // Authenticate
        session.authenticate('authenticator:oauth2', username, password)['catch']((function (reason) {
          var reasonObj = JSON.parse(reason);

          ctrl.set('error', reasonObj.errors[0] ? reasonObj.errors[0].detail : this.get('genericError'));
        }).bind(this));
      },
      cancel: function cancel() {
        this.transitionTo('main.about-me');
      },
      willTransition: function willTransition() {
        $('#login-modal').modal('hide');
      }
    }
  });
});