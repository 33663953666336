define('personal-website/components/wysiwyg-editor/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    froalaEditor: {
      params: {
        toolbarInline: true,
        placeholderText: 'Enter..'
        // For more params refer: 'https://www.froala.com/wysiwyg-editor/docs/options'
      }
    },
    tagName: ''
  });
});