define('personal-website/main/blog/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    // attributes
    session: service('session'),
    sessionAccount: service('session-account'),
    // hooks
    model: function model() {
      return this.modelFor('main').get('blogPosts');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
      controller.set('selected', this.store.createRecord('blog-post'));
    },
    // actions
    actions: {
      cancel: function cancel() {
        this.set('selected', this.store.createRecord('blog-post'));
      },
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/blog.jpg)');
      },
      save: function save() {
        var post = this.controller.get('selected');

        this.get('sessionAccount.profile').then((function (person) {
          post.set('person', person);

          post.save();
        }).bind(this));
      },
      select: function select(post) {
        this.controller.set('selected', post);
      },
      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});