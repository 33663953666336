define('personal-website/models/project', ['exports', 'ember-data', 'personal-website/mixins/imagable'], function (exports, _emberData, _personalWebsiteMixinsImagable) {
  exports['default'] = _emberData['default'].Model.extend(_personalWebsiteMixinsImagable['default'], {
    //attributes
    description: _emberData['default'].attr('string'),
    ended: _emberData['default'].attr('date'),
    name: _emberData['default'].attr('string'),
    started: _emberData['default'].attr('date'),
    url: _emberData['default'].attr('string'),
    //relationships
    skills: _emberData['default'].hasMany('skill')
  });
});