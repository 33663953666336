define('personal-website/models/skill', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // attributes
    color: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    hoverColor: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    years: _emberData['default'].attr('number'),
    // relationships
    person: _emberData['default'].belongsTo('person'),
    projects: _emberData['default'].hasMany('project', { readOnly: true })
  });
});