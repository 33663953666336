define('personal-website/components/nav-bar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // attributes
    tagName: '',
    // computed properties
    isDev: _ember['default'].computed('environment', function () {
      return this.get('environment') === 'development';
    }),
    // hooks
    didInsertElement: function didInsertElement() {
      $(this.element).attr('role', 'navigation');
    },
    // actions
    actions: {
      login: function login() {
        this.sendAction('login');
      },
      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});