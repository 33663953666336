define('personal-website/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: service('session'),
    actions: {
      didTransition: function didTransition() {
        $('body').removeClass('modal-open');
      },
      error: function error(_error /* , transition */) {
        if (_error) {
          this.transitionTo('error');
        }
      }
    }
  });
});