define('personal-website/main/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    session: service('session'),
    model: function model() {
      return this.store.findRecord('person', 1);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
    },
    actions: {
      didTransition: function didTransition() {
        $('.title-card').switchClass('container small-title', 'content-section title-card');
        this.controller.set('fullTitle', true);
        return true; // Bubble the didTransition event
      },
      login: function login() {
        this.transitionTo(this.get('session.isAuthenticated') ? 'main.admin' : 'login');
      },
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});