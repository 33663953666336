define('personal-website/main/education/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    setupController: function setupController(controller, model) {
      var auth = this.get('session.isAuthenticated');

      this._super(controller, model);

      controller.set('isAuthenticated', auth);
      if (auth) {
        controller.set('selected', this.store.createRecord('education'));
      }
    },
    actions: {
      cancel: function cancel() {
        this.set('selected', this.store.createRecord('education'));
      },
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/education.jpg)');
      },
      save: function save() {
        var ed = this.controller.get('selected');

        this.get('sessionAccount.profile').then((function (person) {
          ed.set('person', person);

          ed.save();
        }).bind(this));
      },
      select: function select(ed) {
        this.controller.set('selected', ed);

        $('#modal-toggle').click();
      },
      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});