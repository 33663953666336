define('personal-website/main/skills/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    // hooks
    model: function model() {
      return this.modelFor('main').get('skills').then(function (skills) {
        return skills;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.hasOwnProperty('sortedSkills')) {
        delete controller.sortedSkills;
      }

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
      controller.set('sort', ['years:desc']);
      controller.set('selected', this.store.createRecord('skill'));
      controller.set('sortedSkills', _ember['default'].computed.sort('model', 'sort'));
    },
    // actions
    actions: {
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/skills.jpg)');
      },
      remove: function remove(skill) {
        skill.destroyRecord();

        this.refresh();
      },
      save: function save() {
        var skill = this.controller.get('selected');

        this.get('sessionAccount.profile').then((function (person) {
          skill.set('person', person);

          skill.save();
        }).bind(this));
      },
      select: function select(skill) {
        this.controller.set('selected', skill);
      },
      willTranstition: function willTranstition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});