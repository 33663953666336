define('personal-website/components/photo-stack/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //attributes
    tagName: '',
    //computed properties
    carouselId: _ember['default'].computed('modal-id', function () {
      return this.get('modal-id') + '-carousel';
    }),
    dataTarget: _ember['default'].computed('modal-id', function () {
      return '#' + this.get('modal-id');
    }),
    firstImage: _ember['default'].computed('model', function () {
      return this.get('model').get('firstObject');
    }),
    didInsertElement: function didInsertElement() {
      $('#' + this.get('carouselId')).carousel();
    },
    actions: {
      selectImages: function selectImages() {
        this.sendAction('selectImages', this.get('model'));
      }
    }
  });
});