define('personal-website/authenticators/oauth2', ['exports', 'ember', 'personal-website/config/environment', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _ember, _personalWebsiteConfigEnvironment, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant) {
  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    serverTokenEndpoint: _personalWebsiteConfigEnvironment['default'].APP.apiURL + '/login',
    refreshAccessTokens: true,
    serverTokenRevocationEndpoint: _personalWebsiteConfigEnvironment['default'].APP.apiURL + '/logout',
    authenticate: function authenticate(username, password) {
      return new _ember['default'].RSVP.Promise((function (resolve, reject) {
        _ember['default'].$.ajax({
          url: this.serverTokenEndpoint,
          type: 'POST',
          data: JSON.stringify({
            username: username,
            password: password
          }),
          headers: {
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json;charset=utf-8'
          },
          dataType: 'json'
        }).then(function (response) {
          _ember['default'].run(function () {
            resolve({
              access_token: response.authentication_token
            });
          });
        }, function (xhr) {
          var response = xhr.responseText;
          _ember['default'].run(function () {
            reject(response);
          });
        });
      }).bind(this));
    }
  });
});