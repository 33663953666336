define('personal-website/models/education', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // attributes
    description: _emberData['default'].attr('string'),
    graduated: _emberData['default'].attr('date'),
    schoolName: _emberData['default'].attr('string'),
    started: _emberData['default'].attr('date'),
    // relationships
    person: _emberData['default'].belongsTo('person')
  });
});