define('personal-website/components/filestack-upload/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // attributes
    tagName: '',
    // hooks
    didInsertElement: function didInsertElement() {
      _ember['default'].run.scheduleOnce('afterRender', (function () {
        this.set('client', filestack.init('AGfPm0pN1Q3SY6LVzGqvKz'));
      }).bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var opts = this.get('options');

      this.set('options', opts ? opts : {});
    },
    // actions
    actions: {
      showPicker: function showPicker() {
        this.get('client').pick(this.get('options')).then((function (result) {
          this.sendAction('upload', result.filesUploaded);
        }).bind(this));
      }
    }
  });
});