define('personal-website/main/projects/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    // attributes
    session: service('session'),
    sessionAccount: service('session-account'),
    // hooks
    model: function model() {
      return this.modelFor('main').get('projects').then(function (projects) {
        return projects;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.hasOwnProperty('sortedProjects')) {
        delete controller.sortedProjects;
      }

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
      controller.set('pickOptions', {
        accept: 'image/*',
        fromSources: 'local_file_system',
        minFiles: 1
      });
      controller.set('selected', this.store.createRecord('project'));
      controller.set('sortProperties', ['started:desc']);
      controller.set('sortedProjects', _ember['default'].computed.sort('model', 'sortProperties'));
      controller.addObserver('images', function () {
        if (this.get('images')) {
          $('#image-modal').modal('show');
        }
      });
    },
    // actions
    actions: {
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/project.jpg)');
      },
      imageUpload: function imageUpload(results) {
        var imagable = this.controller.get('selected');

        _ember['default'].RSVP.Promise.resolve(this.get('sessionAccount.profile')).then((function (person) {
          if (results.length) {
            results.map((function (result) {
              this.store.createRecord('image', {
                fileName: result.name,
                url: result.url,
                imagable: imagable,
                uploader: person
              }).save().then((function () {
                this.refresh();
              }).bind(this));
            }).bind(this));
          }
        }).bind(this));
      },
      save: function save() {
        var project = this.controller.get('selected');

        this.get('sessionAccount.profile').then((function (person) {
          project.set('person', person);

          project.save();
        }).bind(this));
      },
      select: function select(project) {
        this.controller.set('selected', project);

        $('#modal-toggle').click();
      },
      selectImages: function selectImages(images, title) {
        var ctrl = this.controller;

        // reset images before selecting the image set
        ctrl.set('images', null);
        ctrl.set('images', images);
        ctrl.set('modal-title', title);
      },
      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});