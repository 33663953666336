define('personal-website/components/project-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //computed properties
    title: _ember['default'].computed('model.name', function () {
      return this.get('model.name') + ' Screenshots';
    }),
    actions: {
      select: function select() {
        this.sendAction('select', this.get('model'));
      },
      selectImages: function selectImages(images) {
        this.sendAction('selectImages', images, this.get('title'));
      }
    }
  });
});