define('personal-website/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (!attribute.options.readOnly) {
        this._super(snapshot, json, key, attribute);
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      if (!relationship.options.readOnly) {
        this._super(snapshot, json, relationship);
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (!relationship.options.readOnly) {
        this._super(snapshot, json, relationship);
      }
    }
  });
});