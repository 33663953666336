define('personal-website/main/work-experience/route', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend({
    // attributes
    session: service('session'),
    sessionAccount: service('session-account'),
    // hooks
    model: function model() {
      return this.modelFor('main').get('workExperiences').then(function (exp) {
        return exp;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.hasOwnProperty('sortedWork')) {
        delete controller.sortedWork;
      }

      controller.set('isAuthenticated', this.get('session.isAuthenticated'));
      controller.set('selected', this.store.createRecord('work-experience'));
      controller.set('sort', ['started:desc']);
      controller.set('sortedWork', _ember['default'].computed.sort('model', 'sort'));
    },
    // actions
    actions: {
      cancel: function cancel() {
        this.set('selected', this.store.createRecord('work-experience'));
      },
      didTransition: function didTransition() {
        this.controllerFor('main').set('style', 'background-image: url(assets/imgs/work.jpg)');
      },
      save: function save() {
        var exp = this.controller.get('selected');

        this.get('sessionAccount.profile').then((function (person) {
          exp.set('person', person);

          exp.save();
        }).bind(this));
      },
      select: function select(exp) {
        this.controller.set('selected', exp);

        $('#modal-toggle').click();
      },

      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});