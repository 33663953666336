define('personal-website/models/person', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  // const log = Ember.Logger.log;

  exports['default'] = _emberData['default'].Model.extend({
    // attributes
    birthDate: _emberData['default'].attr('date'),
    description: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    linkedin: _emberData['default'].attr('string'),
    location: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    resumeUrl: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    // computed properties
    age: _ember['default'].computed('birthDate', function () {
      var birth = (0, _moment['default'])(this.get('birthDate')).toISOString();

      return (0, _moment['default'])().diff(birth, 'years');
    }),
    firstName: _ember['default'].computed('name', function () {
      return this.get('name').split(' ').slice(0, 1);
    }),
    lastName: _ember['default'].computed('name', function () {
      return this.get('name').split(' ').slice(-1);
    }),
    // relationships
    blogPosts: _emberData['default'].hasMany('blog-post'),
    educations: _emberData['default'].hasMany('education'),
    images: _emberData['default'].hasMany('image'),
    profilePicture: _emberData['default'].belongsTo('image', { inverse: null }),
    projects: _emberData['default'].hasMany('projects'),
    skills: _emberData['default'].hasMany('skill'),
    user: _emberData['default'].belongsTo('user'),
    workExperiences: _emberData['default'].hasMany('work-experience')
  });
});