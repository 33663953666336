define('personal-website/components/title-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    actions: {
      alterTitle: function alterTitle() {
        if (this.get('fullTitle')) {
          this.sendAction('alterTitle');
        }
      }
    }
  });
});