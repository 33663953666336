define('personal-website/helpers/merged-hash', ['exports', 'ember-froala-editor/helpers/merged-hash'], function (exports, _emberFroalaEditorHelpersMergedHash) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFroalaEditorHelpersMergedHash['default'];
    }
  });
  Object.defineProperty(exports, 'mergedHash', {
    enumerable: true,
    get: function get() {
      return _emberFroalaEditorHelpersMergedHash.mergedHash;
    }
  });
});