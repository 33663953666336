define('personal-website/router', ['exports', 'ember', 'personal-website/config/environment'], function (exports, _ember, _personalWebsiteConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _personalWebsiteConfigEnvironment['default'].locationType,
    rootURL: _personalWebsiteConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    // due to a bug in Ember 2.11.0, can't declare a custom error route
    // this.route('error');
    this.route('loading');
    this.route('main', { path: '/' }, function () {
      this.route('about-me');
      this.route('education');
      this.route('loading');
      this.route('work-experience');
      this.route('skills');
      this.route('projects');
      this.route('blog');
    });
    this.route('login');
  });

  exports['default'] = Router;
});