define('personal-website/components/skill-chart/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //attributes
    chart: null,
    classNames: [],
    tagName: 'section',
    //computed properties
    colors: _ember['default'].computed('model', function () {
      return this.get('model').map(function (item) {
        return item.get('color');
      });
    }),
    data: _ember['default'].computed('model', function () {
      return this.get('model').map(function (item) {
        return item.get('years');
      });
    }),
    hoverColors: _ember['default'].computed('model', function () {
      return this.get('model').map(function (item) {
        return item.get('hoverColor');
      });
    }),
    labels: _ember['default'].computed('model', function () {
      return this.get('model').map(function (item) {
        return item.get('name');
      });
    }),
    //functions
    createChart: function createChart() {
      var ctx = $('#' + this['chart-id']).get(0).getContext('2d'),
          data = {
        labels: this.get('labels'),
        datasets: [{
          data: this.get('data'),
          backgroundColor: '#8b0000',
          hoverBackgroundColor: '#bf0000',
          label: ''
        }]
      },
          options = {
        scales: {
          yAxes: [{
            ticks: {
              max: 5,
              min: 0,
              stepSize: 0.5
            }
          }]
        },
        legend: {
          display: false
        }
      },
          chart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: options
      });

      this.set('chart', chart);
    },
    didInsertElement: function didInsertElement() {
      this._super();
      this.createChart();
    }
  });
});