define('personal-website/services/session-account', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service(),
    // computed properties
    profile: _ember['default'].computed('session.data.login', function () {
      var accountLogin = this.get('session.data.login');

      if (!_ember['default'].isEmpty(accountLogin)) {
        return this.get('store').query('person', {
          filter: {
            email: accountLogin
          }
        }).then(function (records) {
          return records.toArray()[0];
        });
      } else {
        return null;
      }
    }),
    user: _ember['default'].computed('session.data.login', function () {
      var accountLogin = this.get('session.data.login');

      if (!_ember['default'].isEmpty(accountLogin)) {
        return this.get('store').query('user', {
          filter: {
            username: accountLogin
          }
        }).then(function (records) {
          return records.toArray()[0];
        });
      } else {
        return null;
      }
    })
  });
});