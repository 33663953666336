define('personal-website/loading/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('supportsSVG', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('main').set('style', '');
      }
    }
  });
});