define('personal-website/models/work-experience', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    //attributes
    company: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    ended: _emberData['default'].attr('date'),
    position: _emberData['default'].attr('string'),
    started: _emberData['default'].attr('date'),
    url: _emberData['default'].attr('string'),
    //relationships
    person: _emberData['default'].belongsTo('person'),
    projects: _emberData['default'].hasMany('project')
  });
});