define('personal-website/main/controller', ['exports', 'ember', 'personal-website/config/environment'], function (exports, _ember, _personalWebsiteConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    // attributes
    fullTitle: true,
    // hooks
    init: function init() {
      this.set('environment', _personalWebsiteConfigEnvironment['default'].environment);
    },
    // actions
    actions: {
      alterTitle: function alterTitle() {
        $('.title-card').switchClass('content-section title-card', 'container small-title');

        this.set('fullTitle', false);
        this.transitionToRoute('main.about-me');
      }
    }
  });
});